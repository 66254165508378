<template>
  <div id="productSelectionModal" data-uk-modal>
    <div class="uk-modal-dialog uk-modal-body vb-rounded">
      <div v-if="loading" class="uk-overlay-default vb-overlay-cart uk-position-cover" />
      <div v-if="loading" class="uk-position-center vb-spinner-cart-update" data-uk-spinner />
      <button class="uk-modal-close-default" type="button" data-uk-close />

      <div class="uk-padding uk-text-center uk-padding-remove-vertical uk-margin-top">
        <h5 class="uk-text-bold uk-h3 uk-margin-remove-top uk-margin-small-bottom" style="line-height: 1.125">
          Choose the product to customize
        </h5>
        <p class="uk-text-small uk-margin-remove-top uk-margin-auto-left uk-margin-auto-right">
          Varsity Base offers three types of garments you can customize and personalize - the world famous letterman
          jacket, a varsity coat and a hoodie.
        </p>
      </div>
      <div
        id="grid"
        class="uk-child-width-1-2 uk-child-width-1-4@s uk-grid-match uk-grid-small uk-margin-medium-bottom"
        data-uk-grid
      >
        <div v-for="product in products" :key="product.title">
          <label
            class="w-radio-img uk-position-relative b-radio-img"
            :for="product.title"
            @click="onClick(product.value)"
          >
            <input
              :id="product.title"
              v-model="currentProduct"
              class="uk-radio"
              type="radio"
              :value="product.value"
              required
            />
            <span class="w-radio-img__thumb uk-display-block vb-rounded">
              <img :data-src="getImgPath(product.imgSrc)" :alt="product.title" data-uk-img style="border-radius: 8px" />
            </span>
            <span class="uk-display-block uk-text-center w-label uk-text-bold">{{ product.title }}</span>
          </label>
        </div>
      </div>
      <div class="uk-position-relative uk-margin-bottom" style="height: 48px">
        <div id="ProductSwitcherBtn" class="uk-position-absolute uk-width-1-1 uk-height-1-1 uk-text-center">
          <button
            class="uk-button uk-button-secondary uk-flex-inline uk-text-bold vb-btn-hover-scale"
            style="border-radius: 48px"
            @click="toSelected"
          >
            <span class="" style="margin-left: -6px; width: 24px">
              <img :src="getImgPath('/img/wizard/wizard-icon.svg')" data-uk-svg alt="Personalization icon" />
            </span>
            <span class="uk-margin-remove">Start customize</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'

import { getImgPath } from '@/shared/js/helpers'

export default {
  name: 'ProductSelectionModal',
  data() {
    return {
      products: [
        { title: 'Jacket', value: 'jacket', imgSrc: '/img/wizard/thumbs/jacket.svg' },
        { title: 'Coat', value: 'coat', imgSrc: '/img/wizard/thumbs/coat.svg' },
        { title: 'Hoodie', value: 'hoodie', imgSrc: '/img/wizard/thumbs/hoodie.svg' },
        { title: 'Croptop', value: 'croptop', imgSrc: '/img/wizard/thumbs/croptop.svg' },
      ],
      currentProduct: 'jacket',
      loading: false,
    }
  },
  methods: {
    getImgPath,
    onClick(type) {
      this.currentProduct = type
      UIkit.toggle('ProductSwitcherBtn', {
        target: 'ProductSwitcherBtn',
        animation: 'uk-animation-slide-bottom-small',
      })
    },
    toSelected(e) {
      e.preventDefault()
      this.loading = true
      location.href = `/${this.currentProduct}-builder`
    },
  },
}
</script>
