import CjTracker from '../components/CjTracker.vue'
import NavCartButton from '../components/NavCartButton.vue'
import ProductSelectionModal from '../components/ProductSelectionModal.vue'
import SidebarCart from '../components/SidebarCart.vue'
import SubscribeForm from '../components/SubscribeForm.vue'

const LayoutMixin = {
  components: {
    NavCartButton,
    SidebarCart,
    SubscribeForm,
    ProductSelectionModal,
    CjTracker,
  },
}

export default LayoutMixin
